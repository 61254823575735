{
  "common": {
    "common": "және",
    "show": "Көрсету",
    "showAll": "Барлығын көрсету",
    "search": "Iздеу",
    "showMore": "Көбірек көру",
    "hide": "Жасыру",
    "more": "Тағы",
    "readMore": "Толығырақ оқу",
    "notChosen": "Таңдалмаған",
    "noOptions": "Қолайлы нұсқалар жоқ.",
    "unitsOfMeasurement": {
      "label": "Өлшем бірлігі",
      "options": {
        "squareMeter": "m²",
        "squareFoot": "ft²"
      }
    },
    "from": "Бастап",
    "to": "Дейін",
    "of": "ішінен",
    "in": "in",
    "or": "немесе",
    "yes": "ия",
    "no": "жоқ",
    "go": "Өту",
    "send": "Жіберу",
    "add": "Қосу",
    "edit": "Өңдеу",
    "cancel": "Бас тарту",
    "details": "Толығырақ",
    "supportRequestMessage": "Қолданбаны іске қосу кезінде қате орын алды. Техникалық қолдау қызметіне хабарласыңыз",
    "inDevelopment": "Дамудағы функционалдылық",
    "weekdays": {
      "short": {
        "monday": "Дс",
        "tuesday": "Сс",
        "wednesday": "Ср",
        "thursday": "Бс",
        "friday": "Жм",
        "saturday": "Сн",
        "sunday": "Жк"
      }
    },
    "months": {
      "full": {
        "january": "Қаңтар",
        "february": "Ақпан",
        "march": "Наурыз",
        "april": "Сәуiр",
        "may": "Мамыр",
        "june": "Маусым",
        "july": "Шiлде",
        "august": "Тамыз",
        "september": "Қыркүйек",
        "october": "Қазан",
        "november": "Қараша",
        "december": "Желтоқсан"
      },
      "short": {
        "january": "Қаң",
        "february": "Ақп",
        "march": "Нау",
        "april": "Сәу",
        "may": "Мам",
        "june": "Мау",
        "july": "Шiл",
        "august": "Там",
        "september": "Қыр",
        "october": "Қаз",
        "november": "Қар",
        "december": "Жел"
      },
      "genitive": {
        "january": "қаңтар",
        "february": "ақпан",
        "march": "наурыз",
        "april": "сәуiр",
        "may": "мамыр",
        "june": "маусым",
        "july": "шiлде",
        "august": "тамыз",
        "september": "қыркүйек",
        "october": "қазан",
        "november": "қараша",
        "december": "желтоқсан"
      }
    },
    "time": {
      "at": "в",
      "from": "с",
      "to": "дейін",
      "up": "по",
      "label": {
        "from": "Бастап уақыт",
        "to": "Дейін уақыт"
      }
    },
    "terms": {
      "day": "0 days | {count} day | {count} days"
    },
    "today": "Бүгін",
    "tomorrow": "Ертең",
    "upload": "Жүктеу",
    "available": "Қол жетімді",
    "language": "Тіл",
    "download": "Жүктеп алу",
    "all": "Барлығы",
    "contacts": "Байланыс",
    "save": "Сақтау",
    "noPhoto": "Фотосурет жоқ",
    "countries": {
      "prepositional": {
        "ru": "Ресейде",
        "ge": "Грузияда",
        "kz": "Қазақстанда",
        "uz": "Өзбекстанда",
        "ae": "БАӘ",
        "id": "Индонезияда",
        "cy": "Кипрда",
        "th": "Тайландта"
      }
    },
    "downloadApp": "Біздің қолданбаны жүктеп алыңыз"
  },
  "pageTitles": {
    "home": "Басты бет",
    "login": "Кіру",
    "signup": "Тіркелу",
    "fixation": "Фиксациялар",
    "signupInvitation": "Тіркелу - Шақырға",
    "forgotPassword": "Құпия сөзді қалпына келтіру",
    "buildingPage": "{value}",
    "presentationBuildingPage": "{value}",
    "presentationBuildingPageFromSuggestion": "{value}",
    "market": "Сауда аяқталу",
    "suggestions": "Ұсыныстар",
    "suggestionPage": "Ұсыныс №{value}",
    "notFound": "Бет табылмады",
    "forbidden": "Кіруге рұқсат етілмеді",
    "suggestionsManagement": "Ұсыныстарды басқару",
    "clients": "Мүшелер",
    "changeList": "Жаңартулар",
    "developerPage": "{value}",
    "journal": "Журнал",
    "journalNews": "Жаңалықтар",
    "profileDelete": "Профиль Жойылуда"
  },
  "feedback": {
    "label": "Кері байланыс",
    "options": {
      "suggestIdea": "Идея ұсыну",
      "dataError": "Дереу мәліметтердең қателігін хабарлау",
      "serviceError": "Қызмет күйде деңгейі шараларын хабарлау"
    }
  },
  "favoritesLinks": {
    "label": "Таңдаулы",
    "reset": "Қалпына келтіру",
    "add": "Бет қосу",
    "confirm": {
      "title": "Таңдаулы беттерді бөлектеу керектігіңіз келеді ме?",
      "toasts": {
        "success": "Таңдаулы беттер сәтті бөлектелді",
        "error": "Таңдаулы беттерді бөлектеуде қателік пайда болды"
      }
    }
  },
  "cookie": {
    "title": "Cookie туралы хабарландыру",
    "text": "Біз cookie файлдарын сайтты ыңғайлы және қауіпсіз ету үшін пайдаланамыз. Осы сайтты қолдану барысында, сіз cookie файлдарын пайдалануға келісесіз.",
    "readMore": "Толығырақ",
    "accept": "Жақсы, келесіде көрсетпеу"
  },
  "currency": {
    "label": "Валюта",
    "options": {
      "rub": "₽",
      "usd": "$",
      "eur": "€",
      "kzt": "₸",
      "thb": "฿",
      "gbp": "£"
    }
  },
  "pagination": {
    "goToPage": "Өту"
  },
  "auth": {
    "description": {
      "label": "Estatyx",
      "welcome": "Қош келдіңіз!",
      "into": "Өз тіркелу жазбаңызға кіруді сұраймыз"
    },
    "login": {
      "label": "Кіру",
      "iAgreeText": "Дербес деректерді өңдеуге келісемін",
      "privatePolicy": "құпиялылық саясатына",
      "licenseAgreement": "лицензиялық келісімнің шарттарына сәйкес"
    },
    "resetPassword": {
      "label": "Парольды қайта орнату",
      "description": "Жаңа кілтсөзіңіз алдыңғы пайдаланған кілтсөздерден айырмалы болуы керек.",
      "setPassword": "Жаңа кілтсөзді орнату",
      "toast": {
        "success": "Пароль сәтті түрде өзгерді",
        "error": "Парольды өзгерту кезінде қате пайда болды"
      }
    },
    "signup": {
      "label": "Тіркелу",
      "iAgreeText": "Дербес деректерді өңдеуге келісемін",
      "privatePolicy": "құпиялылық саясатына",
      "licenseAgreement": "лицензиялық келісімнің шарттарына сәйкес"
    },
    "register": {
      "label": "Тіркеу",
      "toast": {
        "label": {
          "success": "Сіздің тіркеуіңіз сәтті өтті!",
          "error": "Тіркеу кезінде қате пайда болды"
        }
      }
    },
    "organisation": {
      "description": {
        "selectOrganisation": "Ұйымды таңдаңыз",
        "someOrganisation": "Сіз бірнеше ұйымда тіркелгенсіз. Мобилдік қосымшада ары қарай жұмыс істеу үшін ұйымды таңдаңыз немесе жаңасын құрыңыз",
        "attention": "Назар аударыңыз!",
        "warning": "НТүймені басу \"Кіру\" арқылы жаңа ұйым құрылады."
      },
      "actions": {
        "selectOrganisation": {
          "error": "Ұйымды таңдау кезінде қате пайда болды"
        },
        "createOrganisation": {
          "label": "Жаңа ұйым жасау",
          "error": "Ұйымды жасау кезінде қате пайда болды"
        }
      },
      "fired": {
        "title": "Кіруге рұқсат жоқ",
        "error": "Қолжетімділікті қалпына келтіру үшін ұйымыңыздағы жауапты адамға хабарласыңыз немесе жаңа ұйым құрыңыз",
        "create": "Ұйым құру"
      }
    },
    "password": {
      "label": "Құпия сөз",
      "placeholder": "Құпия сөзді теріңіз"
    },
    "passwordConfirm": {
      "label": "Құпия сөзді қайтадан енгізіңіз",
      "placeholder": "Құпия сөзді қайталаңыз",
      "error": "Құпия сөздер сәйкес келмейді"
    },
    "forgotPassword": {
      "label": "Құпия сөзді ұмыттыңыз ба?",
      "description": "Электрондық пошта мекенжайын енгізіңіз, біз сізге құпия сөзді қалпына келтіру туралы нұсқаулар жібереміз",
      "send": "Жіберу",
      "toast": {
        "label": {
          "success": "Құпия сөзді қалпына келтіру үшін сілтеме сіздің электронды поштанызға жіберілді!: {value}!",
          "error": "Құпия сөзді сіздің электронды поштанызға жіберу кезінде қате пайда болды "
        }
      }
    },
    "haveAccount": {
      "label": "Тіркелгеніңіз бар ма?"
    },
    "username": {
      "label": "Логин",
      "placeholder": "Электронды пошта немесе телефон нөмірін енгізіңіз",
      "error": "Мекенжайымыз телефон нөмірі немесе электронды пошта мекенжайы болуы тиіс"
    },
    "name": {
      "label": "Атыңыз",
      "placeholder": "Атыңызды енгізіңіз",
      "error": "Атыңыз міндетті толтырылуы тиіс"
    },
    "lastName": {
      "label": "Тегі",
      "placeholder": "Тегін енгізіңіз"
    },
    "userEmail": {
      "label": "E-mail",
      "placeholder": "Электронды поштаңызды енгізіңіз",
      "error": "Өрісте электрондық пошта мекенжайы болуы керек"
    },
    "userPhone": {
      "label": "Телефон нөмірі",
      "placeholder": "Телефон нөмірін енгізіңіз",
      "error": "Өрісте телефон нөмірі болуы керек"
    },
    "backendValidation": {
      "error": "Пайдаланушы аты немесе құпия сөз дұрыс емес"
    },
    "invitationSignup": {
      "messages": {
        "success": "Сіз жасаған аккаунтыңыз ұйымға қосылады {value}.",
        "linkError": "Шақыру сілтемесі дұрыс емес. Жаңа сілтеме сұраңыз немесе техникалық қолдауға жазыңыз {value}",
        "error": "Ұйым туралы ақпарат алу мүмкін болмады. Қате: {value}"
      },
      "toLogin": "Бар аккаунттан қосылу",
      "loginInfoMessage": "Сіздің аккаунтыңыз ұйымға қосылады {value}"
    },
    "exchangeToken": {
      "toast": {
        "label": {
          "error": "CRM-ге кіру мүмкін болмады"
        }
      }
    }
  },
  "organisation": {
    "form": {
      "id": {
        "label": "Ұйымның ID"
      },
      "createAt": {
        "label": "Ұйымды қосу"
      },
      "name": {
        "label": "Атау",
        "placeholder": "Ұйым атауын енгізіңіз"
      },
      "email": {
        "label": "E-mail",
        "placeholder": "Электронды поштаны енгізіңіз"
      },
      "phone": {
        "label": "Негізгі телефон",
        "placeholder": "+ 7 901 234 56 78"
      },
      "site": {
        "label": "Ұйым сайты",
        "placeholder": "Сайтты енгізіңіз немесе өрісті бос қалдырыңыз"
      },
      "country": {
        "label": "Ел"
      },
      "type": {
        "label": "Шот түрі"
      },
      "city": {
        "label": "Қала",
        "placeholder": {
          "label": "Қаланы енгізіп тізімнен таңдаңыз"
        }
      },
      "actions": {
        "save": {
          "label": "Сақтау"
        },
        "fireUser": {
          "label": "Cіз жоққа сенімдісіз бе {firstName} {lastName} ?"
        }
      },
      "toast": {
        "success": {
          "label": "Ұйым ақпараттары сәтті сақталды!"
        },
        "error": {
          "label": "Деректерді сақтау кезінде қате пайда болды."
        }
      },
      "toasts": {
        "fireUser": {
          "success": "Пайдаланушы сәтті түсірілді!",
          "error": "Пайдаланушыны түсіру кезінде қате пайда болды"
        },
        "updateRole": {
          "success": "Мәртебе сәтті өзгертілді!",
          "error": "Мәртебені өзгерту кезінде қате пайда болды"
        }
      }
    },
    "types": {
      "agency": {
        "label": "Жылжымайтын мүлік агенттігі"
      },
      "privateRealtor": {
        "label": "Жеке риэлтор"
      },
      "developer": {
        "label": "Құрылыс компаниясы"
      },
      "integrator": {
        "label": "Интегратор / Bitrix24 серіктесі"
      },
      "managementCompany": {
        "label": "Басқару компаниясы"
      },
      "other": {
        "label": "Басқа"
      }
    },
    "moderation": {
      "onModeration": {
        "label": "Модерацияда",
        "title": "Сіздің ұйымыңыз модерацияға жіберілді",
        "text": "Тексеруден кейін қызметке кіру мүмкіндігі беріледі"
      },
      "denied": {
        "label": "Кіруге рұқсат жоқ",
        "title": "Кіруге рұқсат жоқ",
        "text": "Толық ақпарат алу үшін серіктестермен жұмыс жөніндегі менеджерге хабарласыңыз"
      },
      "contacts": {
        "label": "Байланыс үшін мекенжай"
      }
    },
    "settings": {
      "page": {
        "toggle": {
          "options": {
            "profile": "Профиль",
            "settings": "Настройки",
            "integration": "Интеграция",
            "employees": "Сотрудники",
            "roles": "Роли"
          }
        },
        "profile": {
          "title": "Профиль компании"
        },
        "settings": {
          "title": "Параметрлер"
        },
        "integration": {
          "title": "Интеграция"
        },
        "employees": {
          "title": "Қызметкерлер",
          "invitation": {
            "link": "Шақыру үшін сілтеме",
            "tooltip": {
              "copy": "Көшіру",
              "change": "Өзгерту"
            }
          },
          "table": {
            "head": {
              "id": "ID",
              "phone": "Телефон",
              "email": "Электронды пошта",
              "role": "Pөл"
            }
          }
        },
        "roles": {
          "title": "Рөлдер",
          "user": "Пайдаланушы",
          "admin": "Әкімші"
        }
      },
      "form": {
        "profile": {
          "id": "Ұйымның ID-сі:",
          "connectionTime": "Ұйымды қосу:",
          "about": {
            "title": "Компания туралы",
            "name": {
              "label": "Атауы",
              "placeholder": "Ұйым атауы"
            },
            "type": {
              "label": "Ұйым түрі"
            }
          },
          "address": {
            "title": "Орналасуы",
            "country": {
              "label": "Елді таңдаңыз",
              "placeholder": "Ел"
            },
            "city": {
              "label": "Қаланы таңдаңыз",
              "placeholder": "Қала"
            }
          },
          "contacts": {
            "title": "Контактілер",
            "phone": {
              "label": "Негізгі телефон",
              "placeholder": "+7 000 000 00 00"
            },
            "email": {
              "label": "E-mail"
            }
          }
        }
      }
    }
  },
  "validation": {
    "messages": {
      "min": "Өріс {label} кемінде {length} таңбаларынан тұруы керек",
      "minValue": "Өріс {label} кем болмауы керек {min}",
      "max": "Өріс {label} көптегенде {length} таңбаларынан тұруы керек",
      "required": "{label} міндетті өріс",
      "url": "{label} өрісі жарамды URL мекенжайы болуы керек",
      "oneFieldRequired": "{firstLabel} бұл міндетті өріс, егер \"{secondLabel}\" өрісі толтырылмаса"
    }
  },
  "address": {
    "select": {
      "button": {
        "label": "Ел"
      },
      "modal": {
        "title": {
          "choose": "Таңдау",
          "country": "Елдер",
          "region": "Аймақтар",
          "city": "Қалалар"
        }
      }
    },
    "allCountries": "Барлық елдер",
    "country": {
      "placeholder": "Елді енгізіңіз"
    },
    "region": {
      "placeholder": "Аймақты енгізіңіз",
      "empty": "ТК табылмады"
    },
    "city": {
      "placeholder": "Қаланы енгізіңіз"
    },
    "toast": {
      "error": "Жүктеу уақытында қате пайда болды"
    }
  },
  "building": {
    "entityName": "ТК",
    "name": {
      "default": "Аты жоқ"
    },
    "promotions": {
      "label": "ЖК акциялары",
      "allPromotions": "Барлық ЖК акциялары",
      "showAllPromotions": "Барлық ЖК акцияларын көру",
      "showButton": "Көру",
      "promotionsNotFound": "ЖК акциялары табылмады",
      "priceWithoutPromo": "Баға акциялар мен жеңілдіктерді есепке алмай көрсетілген",
      "promotionInterval": "Акция әрекет етеді",
      "unlimited": "мерзімсіз"
    },
    "classes": {
      "label": "ТК классы",
      "business": {
        "label": "Бизнес"
      },
      "comfort": {
        "label": "Комфорт"
      },
      "economy": {
        "label": "Эконом"
      },
      "elite": {
        "label": "Элит"
      }
    },
    "developers": {
      "notFound": "Кешірек, таңдалған аймақта құрылымшылар туралы статистика қазіргі уақытта жоқ"
    },
    "banks": {
      "notFound": "Кешірек, таңдалған сіздің аймағыңызда қазіргі уақытта банктар туралы статистика жоқ"
    },
    "agencies": {
      "notFound": "Кешірек, таңдалған сіздің аймағыңызда қазіргі уақытта агенттіктер туралы статистика жоқ"
    },
    "popular": {
      "label": "Танымал тұрғын үй кешендері",
      "jkNotFound": "Өкінішке орай, сіз таңдаған аймақтағы танымал тұрғын үй кешендерінің статистикасы әлі жоқ"
    },
    "constructionProgress": {
      "quarter": {
        "label": "{value} Тоқсан"
      },
      "photos": {
        "label": {
          "full": "фотосуреттер жоқ | {count} фотосурет | {count} фотосуреттер | {count} фотосурет"
        }
      },
      "video": {
        "label": {
          "full": "бейне жоқ | {count} бейне | {count} бейне | {count} бейне"
        }
      },
      "options": {
        "all": "Барлық үйлер",
        "building": "Жалпы жоспар"
      }
    },
    "houses": {
      "label": {
        "short": "корпустар жоқ  | {count} к. | {count} к. | {count} к.",
        "full": "корпустар жоқ  | {count} корпус | {count} корпус | {count} корпустар"
      },
      "allHouses": "Барлық корпустар"
    },
    "contacts": {
      "label": "Байланыс",
      "forCommunication": "Байланыс мәліметтері"
    },
    "withoutContacts": {
      "label": "Байланыс жоқ"
    },
    "timeConstraints": {
      "quarter": {
        "label": {
          "short": "пәтер"
        },
        "options": {
          "first": "1",
          "second": "2",
          "third": "3",
          "fourth": "4"
        }
      },
      "year": {
        "label": {
          "short": "ж.",
          "full": "жыл"
        }
      },
      "yearQuarter": "{quarter} пт. {year} ж.",
      "salesStart": {
        "label": "Сату басталды"
      },
      "keysGetting": {
        "label": "Кілттерді берілуі",
        "gettingNow": "Беру процесі"
      },
      "deadLine": {
        "label": "Тапсыру мерзімі"
      }
    },
    "flatsInfo": {
      "flats": {
        "label": {
          "full": "Пәтерлер",
          "short": "Пәтер"
        },
        "count": "Пәтерлер саны",
        "fullCountLabel": "Сатылатын пәтерлер саны",
        "flatsTotalCountLabel": "Пәтерлер саны"
      },
      "flatRoomsCount": {
        "label": "Бөлмелер",
        "one": "1",
        "two": "2",
        "three": "3",
        "four": "4",
        "five": "5",
        "six": "6",
        "seven": "7",
        "eight": "8",
        "nine": "9+",
        "studio": "Студия",
        "notIndicated": {
          "label": "Бөлмелер саны көрсетілмеген",
          "full": "көрсетілмеген",
          "short": "көрсетілмеген"
        },
        "openPlan": "Бос жоспар"
      },
      "flatRoomsCountBuildingCard": {
        "one": "1 б. пт.",
        "two": "2 б. пт.",
        "three": "3 б. пт.",
        "four": "4 б. пт.",
        "five": "5 б. пт.",
        "six": "6 б. пт.",
        "seven": "7 б. пт.",
        "eight": "8 б. пт.",
        "nine": "9+ б. пт.",
        "studio": "студ.",
        "notIndicated": {
          "label": "Бөлмелер саны көрсетілмеген",
          "full": "көрсетілмеген",
          "short": "көрсетілмеген"
        },
        "openPlan": "Бос жоспар"
      },
      "totalArea": {
        "label": "Аумақ",
        "from": "м² {value} бастап"
      },
      "price": {
        "label": "Баға",
        "from": "бастап {value}"
      }
    },
    "metroInfo": {
      "distance": "{value} дейінн"
    },
    "motivation": {
      "label": "Мотивация"
    },
    "escrow": {
      "label": "Эскроу",
      "options": {
        "true": "Бар",
        "false": "Жоқ"
      }
    },
    "actions": {
      "motivation": {
        "label": "Мотивация",
        "commission": "Комиссия {value}% дейін",
        "linkDescriptionPartOne": "Төлемдер мен ұсыныстар",
        "linkDescriptionPartSecond": "комиссиясына жіберілді",
        "link": "сілтеме",
        "commissionSum": "Дейін {amount} {currency}",
        "notFound": "Комиссия көрсетілмеген"
      },
      "generalPlan": {
        "label": "Жалпы жоспар"
      },
      "videoReview": {
        "label": "Видеошолу"
      },
      "layout": {
        "label": "Жөндеу кезеңі"
      },
      "panorama": {
        "label": "Панорама"
      },
      "aerialPanorama": {
        "label": "Аэропанорама"
      },
      "addToSuggestion": {
        "label": "Жинаққа қосу"
      },
      "priceStatistics": {
        "label": "м²-дағы бағалардың динамикасы"
      },
      "addedToSuggestion": {
        "label": "Жинаққа қосылды"
      },
      "linkToSuggestion": {
        "label": "Таңдауға сілтеме"
      },
      "linkToPresentation": {
        "label": "Презентацияға сілтеме",
        "toast": {
          "success": "Сілтеме көшірмелді"
        }
      },
      "lookAtTheMap": {
        "label": "Картадан қарау"
      },
      "booking": {
        "label": "Броньдау"
      },
      "fixingTheClient": {
        "label": "Клиентті бекіту"
      },
      "fixViewing": {
        "label": "Көруді тіркеу"
      },
      "constructionProgress": {
        "label": "Құрылысты жағдайы",
        "emptyConstructionProgress": "Құрылысты жағдайы туралы ақпарат жоқ."
      },
      "addToFavorites": {
        "label": "Таңдауларға қосу"
      },
      "addToComparison": {
        "label": "Салыстыруға қосу"
      },
      "lookAtTheCondition": {
        "label": "Шарттар / Құндылық / Регламент"
      },
      "chessboard": {
        "label": "Шахматка"
      }
    },
    "page": {
      "media": "Фото және видео",
      "totalArea": "Пәтерлер",
      "about": "ТҮ туралы { value }",
      "decoration": "Жөндеу кезеңі",
      "advantages": "Артықшылықтар",
      "flats": {
        "label": {
          "full": "Пәтерлер",
          "short": "Пәтерлер"
        }
      },
      "constructionProgress": {
        "label": {
          "full": "Құрылысты жағдайы",
          "short": "Құрылысты жағдайы"
        }
      },
      "files": "Файлдар",
      "mortgage": "Ипотека",
      "ambassador": {
        "title": "Барлығын мамандардан біліңіз",
        "subtitle": "Біз нарықтағы жылжымайтын мүліктің ең үлкен таңдауын ұсынатын және әрбір мүліктің өтімділігіне талдау жасайтын жалғыз компаниямыз. Estatyx мамандары Сіздің арманыңыздағы пәтерді қалай қауіпсіз сатып алу керектігін және олардан жоғары табыс табу жолын біледі.",
        "userInfo": "{country} амбассадор",
        "actions": {
          "contactExpert": {
            "label": "Маманға хабарласыңыз",
            "messageForWhatsApp": "Қайырлы күн.%0A Мен ЖК бойынша \"{buildingName}\"%0A{buildingLink}"
          },
          "bookViewing": {
            "label": "Демонстрацияға жазылу",
            "messageForWhatsApp": "Қайырлы күн.%0AМен ЖК демонстрациясына жазылғым келеді \"{buildingName}\"%0A{buildingLink}"
          }
        }
      }
    },
    "image": {
      "alt": "ТҮ суреті"
    },
    "state": {
      "label": "Үй жасау кезеңі",
      "viewLabel": "Үй жасау кезеңі",
      "viewValue": "%{value}",
      "options": {
        "built": "Үй жасалды, бірақ берілмеген",
        "handOver": "Тапсырылды",
        "unfinished": "Салынуда"
      }
    },
    "presentation": {
      "actions": {
        "returnToSuggestion": {
          "label": "Таңдауды қайта ашу"
        }
      },
      "bookViewing": {
        "textToSend": "ТҮ көруге жазылғым келеді {building}. Қашан көре аламыз?"
      },
      "flats": {
        "houses": {
          "managerChosen": "Риэлтор тарапынан таңдалған объекттер"
        }
      }
    }
  },
  "flat": {
    "entityName": "Пәтерлер",
    "popular": {
      "label": "Popular Flats",
      "flatsNotFound": "Unfortunately, there are no statistics on popular flats in your chosen region yet"
    },
    "decorationType": {
      "label": "Жөндеу кезеңі",
      "emptyDecoration": "Жөндеу кезеңі туралы ақпарат жоқ.",
      "default": "Жөндеу кезеңі әдепкі бойынша",
      "options": {
        "label": "Жөндеу кезеңі варианттары",
        "withoutPreparation": {
          "short": "Жөндеусіз.",
          "full": "Жөндеусіз"
        },
        "preparation": {
          "short": "Дайындық",
          "full": "Дайындық кезеңі"
        },
        "preFinishing": {
          "short": "Ақ жақтау.",
          "full": "Ақ жақтау"
        },
        "fine": {
          "short": "Жөндеумен",
          "full": "Жөндеумен"
        },
        "furnished": {
          "short": "Жиһазбен",
          "full": "Жиһазбен"
        },
        "partlyFurnished": {
          "short": "Жиһазбен. т. е.",
          "full": "Жиһазбен (Толық емес)"
        }
      }
    },
    "windowViewType": {
      "label": "Терезеден көрініс",
      "options": {
        "street": {
          "label": "Көшеге"
        },
        "yard": {
          "label": "Дворға"
        },
        "mountains": {
          "label": "Тауларга"
        },
        "yardAndStreet": {
          "label": "Көше мен дворға"
        },
        "sea": {
          "label": "Теңізге"
        },
        "river": {
          "label": "Көлге"
        },
        "city": {
          "label": "Қалаға"
        }
      }
    },
    "cardinalDirections": {
      "options": {
        "north": {
          "label": "солтүстік"
        },
        "south": {
          "label": "оңтүстік"
        },
        "west": {
          "label": "батыс"
        },
        "east": {
          "label": "шығыс"
        },
        "northeast": {
          "label": "солтүстік-шығыс"
        },
        "southeast": {
          "label": "оңтүстік-шығыс"
        },
        "southwest": {
          "label": "оңтүстік-батыс"
        },
        "northwest": {
          "label": "солтүстік-батыс"
        }
      }
    },
    "paymentType": {
      "label": "Төлеу",
      "options": {
        "mortgage": "Ипотека",
        "cash": "Қолма-қол ақша",
        "certificate": "Сертификат",
        "maternityCapital": "Ана капиталы",
        "installmentPlan": "Рассрочка"
      }
    },
    "contractType": {
      "label": "Келісім",
      "options": {
        "assignment": "Уступка",
        "purchaseAndSaleAgreement": "Сатып алу шарты"
      }
    },
    "totalArea": {
      "label": "Жалпы аумақ",
      "value": "{value} м²",
      "from": "м² {value} бастап",
      "to": "м² {value} дейін"
    },
    "kitchenArea": {
      "label": "Кухня аумағы",
      "value": "{value} м²"
    },
    "floorNumber": {
      "label": "Кабат",
      "value": "{value} эт.",
      "from": "қабат {value} бастап.",
      "to": "қабат {value} дейін.",
      "action": {
        "label": "Кабатты таңдаңыз"
      }
    },
    "lastFloor": {
      "label": "Соңғы"
    },
    "notFirstFloor": {
      "label": "Бірінші емес"
    },
    "price": {
      "from": "бастап {amount} {currency}",
      "to": "дейін {amount} {currency}"
    },
    "pricePerSquareMeter": "{amount} {currency}/м²",
    "status": {
      "label": "Қол жетімді",
      "options": {
        "free": "Сатып алу үшін қол жетімді",
        "booked": "Брондалған",
        "sold": "Сатылған",
        "unavailable": "Қол жетімді емес"
      }
    },
    "flatNumber": "Пәтер № {value}",
    "section": "секц. {value}",
    "table": {
      "entityName": "Кесте",
      "section": "Секция",
      "floor": "Кабат",
      "flatNumber": "пәтер №.",
      "totalArea": "Жалпы аумақ",
      "kitchenArea": "Кухня аумағы",
      "windowViewType": "Көліктен көрініс",
      "cardinalDirection": "Ардинал бағыт",
      "price": "Жалпы баға",
      "pricePerSquareMeter": "м² бағасы",
      "status": "Статус",
      "building": "ТҮ"
    },
    "card": {
      "flatNumber": "№ {value}"
    },
    "grid": {
      "entityName": "Плиткалар"
    },
    "chessboard": {
      "entityName": "Шахматтағы пәтерлер"
    },
    "order": {
      "full": "{value} кезек",
      "short": "{value} кез."
    },
    "button": {
      "book": {
        "label": "Брондау"
      },
      "showAvailableFlats": {
        "show": {
          "label": "Қол жетімді емес пәтерлерді көрсету"
        },
        "hide": {
          "label": "Қол жетімді емес пәтерлерді жасыру"
        }
      },
      "bookViewing": {
        "label": "Көру үшін тіркеу",
        "messageForWhatsApp": "Пәтерді көруге жазылғым келеді № {flatNumber} ТҮ {buildingName} үйде {houseName}%0AБағасы- {price}%0AАуданы - {totalArea}%0AОрналасу - {layoutName}%0AЖасау кезеңі - {decorationType}%0A%0AҚашан көре аламыз?",
        "messageInLayoutForWhatsApp": "Пәтерді көруге жазылғым келеді Орналасу - {layoutName} ТҮ {buildingName} үйде {houseName}%0AБағасы- {price}%0AАуданы - {totalArea}%0A%0AҚашан көре аламыз?",
        "messageDefaultField": "ақпарат берілмеген"
      },
      "bookFlat": {
        "label": "Қала тіркеу",
        "messageForWhatsApp": "Мен қала тіркеусіңіз келеді № {flatNumber} ТҮ {buildingName} үйде {houseName}%0AБағасы- {price}%0AАуданы - {totalArea}%0AОрналасу - {layoutName}%0AЖасау кезеңі - {decorationType}%0A%0AҚашан көре аламыз?",
        "messageInLayoutForWhatsApp": "Мен қала тіркеусіздің келеді Орналасу - {layoutName} ТҮ {buildingName} үйде {houseName}%0AБағасы- {price}%0AАуданы - {totalArea}%0A%0AҚашан көре аламыз?",
        "messageDefaultField": "ақпарат берілмеген"
      }
    },
    "statistics": {
      "price": {
        "title": "Бір м² бағаның динамикасы",
        "toggle": {
          "all": "Барлығы",
          "studio": "Студия",
          "one": "1 к. пәтер",
          "two": "2 к. пәтер",
          "three": "3 к. пәтер"
        },
        "forSquareMeter": "Цена за {currency} м²",
        "table": {
          "date": "Күні",
          "newBuildingPerSquareMeter": "Новостройка за м²",
          "button": "Барлығын көру"
        },
        "notFound": "Өкінішке орай, статистика табылмады"
      }
    }
  },
  "layout": {
    "entityName": "Жоспарлар",
    "image": {
      "alt": "Жоспар"
    }
  },
  "developer": {
    "entityName": "Жасаушы",
    "houses": {
      "handOver": "were handover in",
      "unfinished": "are being built in"
    },
    "foundationYear": "компания құрылған жылы",
    "page": {
      "description": {
        "title": "About the developer",
        "fullShowButton": "Read more"
      },
      "contacts": {
        "info": {
          "text": "Әзірлеушінің контактілерін көру үшін,",
          "login": "кіру",
          "signup": "Тіркелу"
        }
      },
      "advantages": "Advantages",
      "buildings": {
        "title": "Құрылымшыға жататын ЖК",
        "showButton": "Қосымша көрсету {count} ЖК"
      },
      "flats": {
        "title": "Құрылымшыға жататын квартиралар",
        "showButton": "Қосымша көрсету {count} квартир | Қосымша көрсету {count} квартира | Қосымша көрсету {count} квартиралар | Қосымша көрсету {count} квартир"
      }
    },
    "promotions": {
      "label": "Құрылысшының акциялары",
      "allPromotions": "Барлық құрылысшылардың акциялары",
      "showAllPromotions": "Барлық құрылысшылардың акцияларын көру",
      "showButton": "Көру",
      "promotionsNotFound": "Акциялар табылмады"
    }
  },
  "house": {
    "count": "{count} houses|{count} house|{count} houses|{count} houses",
    "type": {
      "label": "Үй түрі",
      "options": {
        "block": "Блокты",
        "frame": "Қаркасты",
        "monolithicFrame": "Монолиттік-қаркасты"
      }
    },
    "facadeType": {
      "label": "Фасад",
      "options": {
        "plaster": "Штукатурка",
        "ventilatedFacade": "Желдетілетін фасад",
        "glass": "Шыны"
      }
    },
    "parkingType": {
      "label": "Паркинг",
      "options": {
        "underground": "Жерасты",
        "indoor": "Жабық",
        "inYard": "Дворда"
      }
    },
    "lifts": {
      "label": "Лифт",
      "noLifts": "Лифт жоқ",
      "liftsExist": "Лифт бар",
      "passengerLiftsCount": {
        "label": "{count} жолаушы лифт|{count} жолаушы лифт|{count} жолаушы лифт|{count} жолаушы лифт"
      },
      "cargoLiftsCount": {
        "label": "{count} жүк лифт|{count} жүк лифт|{count} жүк лифт|{count} жүк лифт"
      }
    }
  },
  "entityActions": {
    "filter": {
      "promotions": {
        "hasPromotions": "Акцияға қатысады"
      },
      "entitiesFilter": {
        "placeholder": "ТҮ, жасаушыды көрсетіңіз",
        "noCountryPlaceholder": "Алдымен мемлекетті таңдау керек"
      },
      "flatFilter": {
        "kitchenArea": {
          "label": "Кухня аумағы",
          "from": "Кухня аумағы {value} м² бастап ",
          "to": "Кухня аумағы {value} м² дейін",
          "action": {
            "label": "Аумақты таңдаңыз"
          }
        },
        "totalArea": {
          "label": "Аумақ",
          "from": "Аумақ {value} м² бастап",
          "to": "Аумақ {value} м² дейін"
        },
        "price": {
          "label": "Цена",
          "from": "Баға {value} бастап",
          "to": "Баға {value} дейін"
        },
        "flatRoomsCount": {
          "one": "1б. п.",
          "two": "2б. п.",
          "three": "3б. п",
          "four": "4б. п",
          "five": "5б. п",
          "six": "6б. п",
          "studio": "студ."
        },
        "flatNumber": {
          "placeholder": "Пәтер нөмірін енгізіңіз"
        }
      },
      "houseFilter": {
        "deadLineYear": {
          "label": "Тапсыру мерзімі",
          "from": "Тапсыру мерзімі {quarter} кв. {year} ж. бастап",
          "to": "Тапсыру мерзімі {quarter} кв. {year} ж. дейін",
          "showHandOver": "Аяқталған үйлерді көрсетіңіз"
        },
        "keysGetting": {
          "label": "Кілттерді беру",
          "from": "Кілттерді беру {quarter} кв. {year} ж. бастап",
          "to": "Кілттерді беру {quarter} кв. {year} ж. дейін",
          "showHandOver": "Берілген үйлерді көрсету",
          "action": {
            "label": "Тапсыру мерзімін таңдау"
          }
        },
        "lifts": {
          "label": "Лифттер",
          "hasPassenger": {
            "label": "Жолаушы"
          },
          "hasCargo": {
            "label": "Жүк тасымалдау"
          }
        },
        "materialTypes": {
          "label": "Материал",
          "action": {
            "label": "Материалды таңдау"
          },
          "aerocreteBlock": {
            "label": "Газдалған бетон"
          },
          "block": {
            "label": "Блокты"
          },
          "boards": {
            "label": "Қалқанды"
          },
          "brick": {
            "label": "Кірпіш "
          },
          "foamConcreteBlock": {
            "label": "Пенобетонды блок"
          },
          "gasSilicateBlock": {
            "label": "Газосиликатты блок"
          },
          "monolith": {
            "label": "Монолитты"
          },
          "monolithBrick": {
            "label": "Монолит-кірпішті"
          },
          "old": {
            "label": "Ескі фонд"
          },
          "panel": {
            "label": "Панельді"
          },
          "stalin": {
            "label": "Сталинский"
          },
          "wireframe": {
            "label": "Сталіндік"
          },
          "wood": {
            "label": "Ағаш"
          },
          "log": {
            "label": "Бревно"
          },
          "timber": {
            "label": "Брус"
          }
        }
      },
      "allFilters": {
        "label": "Барлық фильтр",
        "modal": "Қосымша фильтр"
      },
      "savedFilters": {
        "label": {
          "short": "Сақталған фильтр",
          "full": "Сақталған фильтр"
        },
        "modal": "Сақталған фильтр"
      },
      "selectedFilters": {
        "show": "Қосымша {value}"
      },
      "button": "Фильтр",
      "resetButton": "Қалпына келтіру",
      "actions": {
        "resetFilters": "Фильтрды қалпына келтіру"
      },
      "guest": {
        "title": {
          "partOne": "Халықаралық",
          "partTwo": "жылжымайтын мүлік экожүйесі"
        }
      }
    },
    "search": {
      "flat": "пәтер табылмады | {count} пәтер | {count} пәтерлер | {count} пәтер",
      "mobileFlat": "0 пәтер. | {count} пәтер. | {count} пәтер. | {count} пәтер.",
      "noFlat": "Қол жетімді пәтерлер жоқ",
      "shortSearchButton": "{count} пәтер | {count} пәтер | {count} пәтер | {count} пәтер",
      "errors": {
        "first": "Қателік орын алды. Бетті жаңартып көріңіз.",
        "second": "Қателік орын алды. Жедел желіге хабарласыңыз."
      }
    },
    "show": {
      "layouts": "{count} пәтер |  {count} пәтер |  {count} пәтер |  {count} пәтер",
      "flats": "{count} пәтер |  {count} пәтер |  {count} пәтер |  {count} пәтер",
      "buildings": "{count} ТҮ"
    },
    "showCount": {
      "layouts": "Жоспар табылмады | {count} жоспар табылды | {count} жоспар табылды | {count} жоспар табылды",
      "flats": "Пәтер табылмады | {count} пәтер табылды | {count} пәтер табылды | {count} пәтер табылды",
      "buildings": "ТҮ табылмады | {count} ТҮ | {count} ТҮ | {count} ТҮ"
    },
    "sort": {
      "options": {
        "price": {
          "label": "Баға бойынша",
          "order": {
            "descending": "Қымбат тұрғылардан бастап",
            "ascending": "Төменгі тұрғылардан бастап"
          }
        },
        "popularity": {
          "label": "Танымдық бойынша",
          "order": {
            "descending": "Ең танымды",
            "ascending": "Ең танымсыз"
          }
        },
        "deadline": {
          "label": "Тапсыру мерзімі бойынша",
          "order": {
            "descending": "Ерте бастап",
            "ascending": "Кеш бастап"
          }
        },
        "area": {
          "label": "Өлшемі бойынша",
          "order": {
            "descending": "Үлкендерден бастап",
            "ascending": "Кішілерден бастап"
          }
        },
        "floor": {
          "label": "Қабат бойынша",
          "order": {
            "descending": "Жоғарғы қабаттардан бастап",
            "ascending": "Төменгі қабаттардан бастап"
          }
        },
        "percentage": {
          "label": "Комиссия пайызы бойынша",
          "order": {
            "descending": "Алдымен азырақ",
            "ascending": "Алдымен көбірек"
          }
        }
      }
    },
    "showMap": "Картада көрсету",
    "mapBuildings": "{count} ТҮ | {count} ТҮ | {count} ТҮ | {count} ТҮ"
  },
  "user": {
    "profile": {
      "avatar": {
        "alt": "Пайдаланушының аватары"
      },
      "page": {
        "toggle": {
          "options": {
            "profile": "Профиль",
            "login": "Кіру",
            "password": "Құпия сөз"
          }
        },
        "deleteAccount": {
          "title": "Профиль жойылуда",
          "inputTitle": "Жою себебін көрсетіңіз",
          "buttonTitle": "Есептік жазбаны жою",
          "sendRequest": "Сұраныс жіберу",
          "toast": {
            "success": "Есептік жазбаны жою сұрауы жіберілді",
            "error": "Жою сұрауын жіберу кезінде қате орын алды"
          }
        }
      },
      "form": {
        "profile": {
          "title": {
            "edit": "Қызметкерді өңдеу"
          },
          "about": {
            "title": "Пайдаланушы туралы",
            "firstName": {
              "label": "Аты",
              "placeholder": "Атын енгізіңіз"
            },
            "lastName": {
              "label": "Тегі",
              "placeholder": "Тегін енгізіңіз"
            }
          },
          "contacts": {
            "title": "Байланыстар",
            "mobilePhone": {
              "label": "Негізгі телефон",
              "placeholder": "+7 000 000 00 00"
            },
            "email": {
              "label": "Электронды пошта"
            },
            "telegramPhone": {
              "label": "Телеграм нөмірі",
              "placeholder": "+7 000 000 00 00"
            },
            "telegramLogin": {
              "label": "Телеграм логині",
              "placeholder": "Логин"
            },
            "whatsappPhone": {
              "label": "WhatsApp нөмірі",
              "placeholder": "+7 000 000 00 00"
            }
          },
          "toasts": {
            "profile": {
              "success": "Профиль сәтті жаңартылды",
              "error": "Профильді жаңарту кезінде қате пайда болды"
            },
            "login": {
              "success": "Кіру сәтті жаңартылды",
              "error": "Кіруді жаңарту кезінде қате пайда болды"
            },
            "password": {
              "success": "Құпия сөз сәтті жаңартылды",
              "error": "Құпия сөзді жаңарту кезінде қате пайда болды"
            },
            "image": {
              "success": "Кескін сәтті жаңартылды",
              "error": "Кескінді жаңарту кезінде қате орын алды"
            }
          }
        },
        "login": {
          "title": "Кіруді өзгерту",
          "mobilePhone": {
            "label": "Телефон нөмірі"
          },
          "currentPassword": {
            "label": "Ағымдағы құпия сөз"
          },
          "submit": "Кіруді өзгерту"
        },
        "password": {
          "title": "Құпия сөзді өзгерту",
          "password": {
            "label": "Жаңа құпия сөз"
          },
          "repeatPassword": {
            "label": "Жаңа құпия сөзді қайталау"
          },
          "submit": "Құпия сөзді өзгерту"
        }
      },
      "personalCabinet": {
        "goTo": {
          "label": "Профильге өту",
          "confirmation": {
            "title": "Растау",
            "text": "Орнатулар мен профильді басқару құралымыз CRM Quick Deal-де жасай аласыз.\nЛогин және құпия сөз Estatyx-те дейінікі тұтынуымен тең.\nБұл уақыттың шешімі және жақын кезде профиль мен орнатуларды басқару Estatyx-те пайда болады.\nТағы да, жұмыс орналары үшін CRM Quick Deal-ге кіріспе аласыз =)\nТүшінішті рақмет."
          }
        }
      }
    }
  },
  "mainMenu": {
    "navLinks": {
      "home": {
        "index": "Басты",
        "market": "Каталог",
        "popular": "Popular",
        "prices": "Prices",
        "partners": "Partners",
        "mortgage": "Mortgage",
        "journal": "Журнал",
        "events": "Оқиғалар"
      }
    }
  },
  "navMenu": {
    "title": "Мәзір",
    "clients": {
      "label": "Клиентпен жұмыс режимі",
      "motivation": "Дала",
      "crm": "CRM"
    },
    "invite": {
      "buttonTitle": "Жетекшілерді шақыру",
      "modalTitle": "Шақыру сілтемесі",
      "tooltip": "Көшірмеу үшін басыңыз",
      "toast": {
        "success": "Сілтеме көшірілді",
        "changeLink": {
          "success": "Сілтемені жаңарту сәтті",
          "error": "Сілтемені жаңарту кезінде қате пайда болды"
        }
      }
    },
    "organisationSettings": {
      "buttonTitle": "Параметрлері"
    },
    "navigation": {
      "suggestions": "Таңдаулар",
      "clients": "Клиенттер",
      "home": "Басты",
      "market": "Каталог",
      "journal": "Журнал",
      "events": "Оқиғалар",
      "settings": "Параметрлер"
    },
    "exit": "Шығу",
    "changeList": {
      "label": "Жаңартулар",
      "version": "Нұсқа",
      "changeListItem": "Жаңарту",
      "badge": "NEW"
    }
  },
  "suggestions": {
    "sidePanel": {
      "displayAllFlats": {
        "button": "Барлықты көрсету",
        "title": "Қазір {value} ұсынылған ТҮ-ден барлық пәтерді көрсетеді",
        "tip": "Белгілі ТҮ-ден барлық пәтерді көру үшін оң жақты жоғарғы бетте ТҮ тақтасын басыңыз"
      }
    },
    "content": {
      "buildingEmpty": "Таңдалған ТҮ-де қосылмаған пәтер жоқ.",
      "suggestionEmpty": "Таңдалған таңдауларда қосылмаған пәтер жоқ."
    },
    "buttons": {
      "bookViewing": {
        "label": "Көру үшін тіркеу",
        "messageForWhatsApp": "Таңдаудан пәтерлерді көруге жазылғым келеді {link}. Қашан көре аламыз?"
      },
      "chatWithManager": {
        "label": "Риелтормен сөйлесу"
      }
    },
    "bottomMenu": {
      "contacts": "Байланыстар"
    },
    "empty": "Таңдау таңбалауы бос",
    "textToSend": "Объекттік таңдау : {value}"
  },
  "suggestionsActions": {
    "filter": {
      "all": "Барлығы",
      "user": "Менің"
    },
    "create": {
      "title": {
        "full": "Таңдау құру",
        "short": "Таңдау"
      },
      "placeholder": "Таңдау атауын енгізіңіз",
      "toast": {
        "success": "Таңдау сәтті жасалды"
      }
    },
    "addToSuggestion": {
      "title": "Объекттерді таңдауга қосу",
      "toast": {
        "success": "Объекттер таңдауға қосылды"
      }
    },
    "removeSuggestion": {
      "toast": {
        "success": "Таңдау сәтті жойылды",
        "error": "Қате пайда болды"
      },
      "confirm": "Сіз  \"{value}\" таңдауды жойғыңыз келетініне сенімдісіз бе?"
    },
    "client": {
      "search": {
        "title": "Бар таңдауды табу",
        "placeholder": "Аты-жөні, телефон немесе e-mail енгізіңіз"
      },
      "add": {
        "toast": {
          "success": "Клиент таңдауга сәтті қосылды."
        }
      },
      "remove": {
        "toast": {
          "success": "Клиент таңдау сәтті айырылды."
        }
      },
      "create": {
        "title": "Жаңадан жасау",
        "fullName": {
          "label": "Клиенттің аты-жөні",
          "placeholder": "Клиенттің аты-жөнін енгізіңіз"
        },
        "mobilePhone": {
          "label": "Телефон",
          "placeholder": "+_ (___) ___-__-__"
        },
        "email": {
          "label": "E-mail",
          "placeholder": "e-mail енгізіңіз"
        },
        "submit": "Клиентті қосу",
        "toast": {
          "success": "Клиент сәтті жаңартылды.",
          "error": "Қате орын алды. Бетті қайта жүктеңіз. Егер мәселе шешілмесе, бізге хабарласыңыз."
        }
      },
      "noClients": "Клиент таңдалмады"
    },
    "removeBuildingFromSuggestion": {
      "toast": {
        "success": "Таңдау жаңартылды"
      },
      "confirm": "Сіз {value} және оның барлық пәтерлерін таңдаудан алып тастағыңыз келетініне сенімдісіз бе?"
    },
    "removeFlatFromSuggestion": {
      "toast": {
        "success": "Таңдау жаңартылды"
      },
      "confirm": "Сіз {value} пәтерін таңдаудан алып тастағыңыз келетініне сенімдісіз бе?"
    },
    "copyLink": {
      "toast": {
        "success": "Таңдау сілтемесі алмасу буферіне көшірілді"
      }
    },
    "table": {
      "suggestionTitle": "Таңдау атауы",
      "objects": "Объектілер",
      "clients": "Клиенттер",
      "creatingDate": "Жасау күні",
      "itemsCount": {
        "buildings": "{value} ТҮ",
        "flats": "{value} пәтер."
      }
    },
    "view": {
      "empty": "Таңдау бос"
    }
  },
  "clients": {
    "page": {
      "title": "Клиенттер",
      "create": {
        "buttonTitle": {
          "full": "Клиентті қосу",
          "short": "Клиент"
        }
      }
    },
    "toasts": {
      "booking": {
        "success": "Бронь сәтті өтті",
        "error": "Бронда қателік пайда болды"
      },
      "fixation": {
        "success": "Фиксация сәтті өтті",
        "error": "Фиксацияның кезінде қателік пайда болды"
      }
    },
    "tableView": {
      "fullName": "Аты-жөні",
      "email": "Email",
      "mobilePhone": "Телефон",
      "action": "Әрекет"
    },
    "delete": {
      "toast": {
        "success": "Клиент сәтті өшірілді."
      }
    },
    "table": {
      "label": "Барлық клиенттер",
      "actions": {
        "addToSuggestion": "Ұсыныстарға қосу",
        "bookings": "Клиенттің резерві",
        "fixations": "Клиенттің фиксациялары",
        "views": "Клиенттердің қараулары",
        "edit": "Клиентті өңдеу",
        "delete": "Клиентті өшіру"
      }
    },
    "filter": {
      "categories": {
        "clients": "Клиент",
        "developers": "Дамушылар",
        "buildings": "Тұрғын үй кешені",
        "assignees": "Жауапты"
      },
      "selected": "Таңдалған",
      "excluded": "Шығарылған"
    },
    "fixation": {
      "table": {
        "label": "Фиксациялар",
        "createDate": "Жасалған күні",
        "client": "Клиент",
        "developer": "Дамушы(лар)",
        "status": "Фиксация мәртебесі",
        "endDate": "Фиксация брондау",
        "assigned": "Мұқиятталған(шы)"
      },
      "actions": {
        "add": "Бекіту"
      },
      "form": {
        "progress": "Брондау",
        "progressStep": "+{value}% прогресске",
        "submit": "Бекіту",
        "developer": {
          "title": "Әзірлеуші",
          "label": "Әзірлеушінің таңдауы",
          "placeholder": "Әзірлеушінің тізімі",
          "conditions": {
            "general": "Ортақ келісім",
            "custom": "Бекіту шарттары"
          }
        },
        "user": {
          "title": "Жауапты",
          "label": "Қолданушының таңдауы",
          "placeholder": "Қолданушылар тізімі"
        },
        "client": {
          "title": "Клиент",
          "label": "Клиентті таңдаңыз немесе жаңасын жасаңыз",
          "add": "Жаңа клиентті қосу",
          "placeholder": "Іздеу үшін аты, телефон немесе поштаны енгізіңіз.",
          "noClients": "Ештеңе табылмады, іздеуді түзетіңіз немесе жаңа клиент жасаңыз"
        },
        "comment": {
          "label": "Пікір",
          "placeholder": "Сіздің пікіріңіз"
        }
      },
      "info": {
        "title": "Артықшылық",
        "text": "Клиентке тапсырыс берудің артықшылықтары туралы біздің мақаладан оқи аласыз",
        "link": "Мұнда оқу"
      },
      "warning": {
        "title": "Ортақ келісім",
        "text": "Егер әзірлеушіде ​​клиентті бекіту шарттары көрсетілмесе, онда клиентті бекіту мерзімі",
        "fixPeriod": "14 күн"
      },
      "fixationTerms": {
        "title": "Бекіту",
        "durationInDays": "Бекіту мерзімі",
        "clientsData": "Бекіту үшін қажетті деректер",
        "additionalInfo": "Қосымша ақпарат"
      },
      "contacts": {
        "title": "Байланыс нөмірлері",
        "mobileTitle": "Байланыс нөмірлері"
      }
    },
    "booking": {
      "table": {
        "label": "Броньдар",
        "createDate": "Жасалған күні",
        "client": "Клиент",
        "developer": "Дамушы",
        "building": "Тұрғын үй кешені",
        "flat": "Пәтер",
        "status": "Брондау мәртебесі",
        "endDate": "Дейін брондау",
        "paymentType": "Төлем",
        "assigned": "Жауапты"
      },
      "actions": {
        "add": "Брондау"
      },
      "paymentType": {
        "options": {
          "mortgage": "Ипотека",
          "cash": "Қолма-қол ақша"
        }
      },
      "form": {
        "progress": {
          "yourCommission": "Сіздің комиссияңыз"
        },
        "deal": {
          "title": "Мәміле",
          "label": "Төлемақы формасы",
          "placeholder": "Төлемақы формасын таңдау",
          "uploadButton": "Құжаттардың сканерлеуін жүктеу"
        },
        "about": {
          "flat": "Пәтер жайлы"
        },
        "submit": "Брондау"
      },
      "warning": {
        "text": "Максималды брондау мерзімі - 3 күн"
      }
    },
    "views": {
      "table": {
        "label": "Көрулер",
        "createDate": "Жасау күні",
        "dateView": "Көру күні",
        "client": "Клиент",
        "building": "ЖК",
        "flat": "Қатар",
        "assigned": "Меселенген(ша)",
        "comment": "Пікір",
        "view": "Көрсету",
        "status": "Көру мәртебесі"
      },
      "statuses": {
        "scheduled": "Тағайындалды",
        "done": "Орындалды",
        "toSchedule": "Күнін растау"
      },
      "actions": {
        "edit": "Өзгерту",
        "add": "Қосу",
        "selectStatus": "Мәртебені таңдау",
        "save": "Көруді сақтау",
        "addView": "Көруді қосу",
        "selectBuilding": "ЖК таңдау",
        "selectFlat": "Қатарды таңдау"
      },
      "status": "Мәртебе",
      "building": "ЖК",
      "chessboard": "Кезекші",
      "aboutClient": "Мүшелер туралы",
      "toasts": {
        "create": {
          "success": "Көру сәтті жасалды",
          "error": "Көруді жасау кезінде қателік пайда болды"
        },
        "update": {
          "success": "Көру сәтті жаңартылды",
          "error": "Көруді жаңарту кезінде қателік пайда болды"
        }
      }
    }
  },
  "mortgage": {
    "label": "Ипотека",
    "calculator": {
      "inputs": {
        "price": {
          "label": {
            "full": "Max Price",
            "short": "бағасы"
          }
        },
        "initialPayment": {
          "label": {
            "full": "Бастапқы төлем",
            "short": "БТ"
          }
        },
        "term": {
          "label": "Мерзімі",
          "suffix": "жылдар | жыл | жылдар | жылдар"
        },
        "stake": {
          "label": "Пайыздық мөлшерлеме",
          "suffix": "жыл"
        }
      },
      "info": {
        "monthlyPayment": "Ай сайынғы төлем",
        "recommendedIncome": "Ұсынылатын табыс"
      }
    },
    "table": {
      "bank": {
        "label": "Банк"
      },
      "stake": {
        "label": "Ставка",
        "stakeRange": {
          "inText": "{value}%-тан",
          "default": "{value}%-тан"
        }
      },
      "maxSum": {
        "label": "Сомасы",
        "range": "{sum} дейін"
      },
      "term": {
        "label": "Мерзім",
        "termRange": "Мерзім таңдалмады | {count} жыл | {count} жылдар | {count} жылдар"
      },
      "monthlyPayment": {
        "label": "ай сайынғы төлем"
      },
      "initialPayment": {
        "label": {
          "full": "Бірінші төлем",
          "short": "I төлем"
        },
        "initialPaymentRange": "{value}%-тан"
      }
    },
    "schedule": {
      "title": "Несиені төлеу кестесі",
      "price": "Бағасы",
      "creditAmount": "Несие сомасы",
      "term": "Мерзімі",
      "overpayment": "Артық төлем",
      "table": {
        "date": "Күні",
        "remainder": "Қалдық",
        "stakes": "Қызығушылық",
        "redeemed": "Өтелді",
        "payment": "Төлем"
      }
    },
    "mortgageActions": {
      "showMortgageList": "Бағамдар табылмады | {count} бағамды қарау | Барлық {count} бағамды қарау | Барлық {count} бағамды қарау",
      "showSchedule": "Төлем кестесі",
      "applyOnline": "Онлайн өтініш беріңіз"
    }
  },
  "files": {
    "label": "Файлдар",
    "actions": {
      "link": "Көшіру",
      "go": "Өту",
      "show": "Барлығын қарау"
    },
    "toast": {
      "success": "Файл сілтемесі буферге көшірілді"
    }
  },
  "chessboard": {
    "house": {
      "empty": "Таңдалған үйде секциялар жоқ"
    },
    "section": {
      "label": "Секция",
      "empty": "Таңдалған тұрғын үй кешеніңде корпус жоқ"
    },
    "floorNumber": {
      "label": "Қабат"
    },
    "modal": {
      "title": "Пәтер туралы ақпарат"
    },
    "empty": "Таңдалған үйде берілген сүзгілерді қанағаттандыратын пәтерлер жоқ"
  },
  "toast": {
    "error": "Қате пайда болды ",
    "title": {
      "success": "Сәтті",
      "info": "Кеңес",
      "warning": "Ескерту",
      "danger": "Кеңес"
    }
  },
  "errorPage": {
    "notFound": "Мұндай бет жоқ!",
    "forbidden": "Кіруге тыйым салынады",
    "backLink": "Негізгі бетке оралу"
  },
  "map": {
    "actions": {
      "showGrid": "Плитка ретінде көрсету",
      "openFilter": "Фильтр",
      "paintOn": "Аймақты белгілеу",
      "painting": "Сурет салуды бастаңыз"
    },
    "building": {
      "notFound": "Аймақта ТҮ табылмады"
    },
    "paintedGeometries": "Бөлінген аймақтар жоқ | {count} картадағы аймақтар | {count} картадағы аймақтар | {count} картадағы аймақтар"
  },
  "footer": {
    "copyright": {
      "title": "©{year} ООО «МЭН» Барлық құқықтары сақталған",
      "subtitle": "Материалдарды пайдалану кезінде гиперсілтеме қажет."
    },
    "subscribe": {
      "title": "Ақпараттық жіберілімге жазылу",
      "inputPlaceholder": "Пошта",
      "button": "Жазылу"
    }
  },
  "partners": {
    "title": "Серіктестер",
    "toggle": {
      "developers": "Әзірлеушілер",
      "agencies": "Агенттіктер",
      "banks": "Банкілер"
    },
    "developers": {
      "houses": {
        "count": "Үйлер жоқ | {count} үй | {count} үй | {count} үй",
        "built": "Үйлер жоқ | тапсырылды | тапсырылды | тапсырылды",
        "unfinished": "құрылып жатыр"
      }
    },
    "agency": {
      "offers": "Ұсыныстар жоқ | {count} ұсыныстар | {count} ұсыныстар | {count} ұсыныстар"
    },
    "bank": {
      "mortgage": "Ипотека  {stake}% бастап"
    }
  },
  "journal": {
    "title": "Журнал",
    "goButton": "Журналға өту",
    "type": {
      "options": {
        "news": "Жаңалық | Жаңалық | Жаңалықтар | Жаңалықтар",
        "promotion": "Акция | Акция | Акциялар | Акциялар",
        "article": "Мақала | Мақала | Мақалалар | Мақалалар",
        "review": "Қарау | Қарау | Қарау | Қарау",
        "training": "Білім беру | Білім беру | Білімдер беру | Білімдер беру",
        "webinar": "Вебинар | Вебинар | Вебинарлар | Вебинарлар",
        "excursion": "Экскурсия | Экскурсия | Экскурсиялар | Экскурсиялар"
      }
    },
    "form": {
      "title": {
        "add": "Бағдарлама қосу"
      },
      "sectionTitle": {
        "content": "Бағдарлама",
        "object": "Объект",
        "author": "Автор",
        "date": "Күні",
        "duration": "Ұзақтығы",
        "seats": "Орын саны"
      },
      "fields": {
        "title": {
          "label": "Тақырып",
          "placeholder": "Мақаланың тақырыбы"
        },
        "text": {
          "label": "Мәтін",
          "placeholder": "Мақаланың мәтіні"
        },
        "shortText": {
          "label": "Қысқа сипаттама",
          "placeholder": "Мақаланың мәтіні"
        },
        "promotionType": {
          "label": "Акция түрі",
          "placeholder": "Таңдау",
          "options": {
            "discount": "Жеңілдеу",
            "sale": "Сатылым",
            "promotion": "Акция",
            "increasedPercentage": "Қосқан пайыз",
            "increasedPercentageShort": "Жоғарылатылған пайыз"
          }
        },
        "file": {
          "button": "Превьюді жүктеп алу"
        },
        "bannerFile": {
          "button": "Баннерді жүктеу"
        },
        "video": {
          "label": "Видеоны қосу",
          "placeholder": "Видео сілтемесі",
          "caption": "Бұл бейне мақала превьюдың артында жасырылады, бірақ пайдаланушы оны көре алады",
          "presentationVisible": "Презентацияда көрсетбеу"
        },
        "developer": {
          "label": "Құрылышшы",
          "placeholder": "Құрылышшылар тізімі",
          "isAll": "Барлық құрылышшылар",
          "isManagerRequestNeeded": "Менеджермен тексерісіз"
        },
        "building": {
          "label": "ҚЖ",
          "placeholder": "ҚЖ-лар тізімі",
          "isAll": "Барлық ҚЖ-лар",
          "isManagerRequestNeeded": "Менеджермен тексерісіз"
        },
        "country": {
          "label": "Ел",
          "placeholder": "Елдер тізімі",
          "isAll": "Барлық елдер",
          "isManagerRequestNeeded": "Менеджермен тексерісіз"
        },
        "address": {
          "label": "Аймақ/Қала",
          "placeholder": "Аймақтар/Қалалар тізімі",
          "isAll": "Барлық аймақтар/қалалар",
          "isManagerRequestNeeded": "Менеджермен тексерісіз"
        },
        "authorship": {
          "label": "Авторство",
          "options": {
            "estatyx": "Estatyx",
            "company": "Компания"
          }
        },
        "company": {
          "label": "Компанияны таңдау",
          "placeholder": "Компаниялар тізімі"
        },
        "user": {
          "label": "Компания қызметкерің таңдау",
          "placeholder": "Қызметкерлер тізімі"
        },
        "date": {
          "label": "Күні",
          "placeholder": "Күнді таңдаңыз"
        },
        "dateRange": {
          "label": "Күнді таңдау"
        },
        "time": {
          "label": "Уақыт"
        },
        "registrationEndDate": {
          "label": "Жазудың аяқталу күні",
          "placeholder": "Жазудың аяқталу күнін таңдаңыз",
          "validation": "{endDate} дан кейінірек болуы мүмкін емес {startDate}"
        },
        "duration": {
          "label": "Ұзақтығы",
          "unlimited": "Мерзімсіз"
        },
        "durationInDays": {
          "label": "Күндер"
        },
        "durationInHours": {
          "label": "Сағат"
        },
        "seats": {
          "label": "Тіркеуге арналған орындар",
          "placeholder": "Қол жетімді орындардың санын енгізіңіз",
          "checkbox": "Орындардың шектеусіз саны"
        }
      },
      "toast": {
        "create": {
          "success": "Мақала сәтті құрылды"
        },
        "edit": {
          "success": "Мақала сәтті өңделді"
        }
      }
    },
    "search": {
      "notFound": "Жаңалық табылмады"
    },
    "filter": {
      "withVideo": "Тек видеомен",
      "promotions": "Акциялар",
      "buildings": "ЖК",
      "developers": "Құрастырушылар"
    },
    "page": {
      "video": "Видео",
      "event": {
        "info": {
          "text": "Іс-шараға қатысу үшін,",
          "login": "кіру",
          "signup": "Тіркелу"
        }
      }
    }
  },
  "events": {
    "page": {
      "title": "Оқиғалар"
    },
    "seatsCount": "{count} орын қалды | {count} орын қалды | {count} орын қалды | {count} орын қалды",
    "mySignUps": "Менің оқиға жазбаларым",
    "status": {
      "seatsUnlimited": "Орындар бар",
      "noSeats": "Орындар қалмады",
      "registrationCompleted": "Жазу аяқталды"
    },
    "filter": {
      "name": "Іздеу..."
    },
    "search": {
      "notFound": "Оқиғалар табылмады"
    },
    "actions": {
      "signUp": "Жазу",
      "canсelSignUp": "Енгізуді тоқтату",
      "noSeats": "Орын жоқ",
      "registrationCompleted": "Жазу аяқталды",
      "eventEnded": "Іс-шара аяқталды"
    },
    "toast": {
      "signUp": {
        "success": "Сіз оқиғаға сәтті тіркелдіңіз!"
      },
      "canсelSignUp": {
        "success": "Тіркеу тоқтатылды!"
      },
      "error": "Іс-шараларды жүктеу уақытында қате пайда болды"
    },
    "tooltipHint": "Жазба тек тіркелген пайдаланушыларға ғана қолжетімді"
  },
  "popularity": {
    "suggestionsAdditionsCount": "{count} ай ішінде таңдау тізіміне қосылды",
    "totalSuggestionsAdditionsCount": "Барлық уақыт ішінде таңдау тізіміне {count} рет қосылды | Барлық уақыт ішінде таңдау тізіміне {count} рет қосылды | Барлық уақыт ішінде таңдау тізіміне {count} рет қосылды | Барлық уақыт ішінде таңдау тізіміне {count} рет қосылды"
  }
}
